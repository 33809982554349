import React, { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../config";
import CircularProgress from "@mui/material/CircularProgress";

const SendMessage: React.FC = () => {
  var sessionId = sessionStorage.getItem("sessionId") || "";
  const [iframeContent, setIframeContent] = useState<string>("");
  const [loading, setloading] = useState<boolean>(true);
  const email = sessionStorage.getItem("email");

  const modifyHtmlContent = (html: string, sessionId: string) => {
    return (
      html
        .replace(
          /https:\/\/app\.telca\.com\/static\//g,
          `${config.autoLoginURL}/`
        )
        .replace(/<nav[\s\S]*?<\/nav>/g, "")
        .replace(
          /<body>/,
          `<body><div id="wrapper" style="margin: 0; padding: 0;">`
        )
        .replace(/ajax.php/g, `ajax.php?sessionId=${sessionId}`)
        .replace(/<\/body>/, `</div></body>`)
        .replace(/<head>/i, `<head><base href="${config.autoLoginURL}/">`) +
      `<style>* { margin: 0; padding: 0; } #page-wrapper { width: auto !important; }</style>`
    );
  };

  const checkLogin = async () => {
    try {
      const sendSmsResponse = await axios.get(
        `${config.autoLoginURL}/proxy/sendsms?sessionId=${sessionId}`,
        {
          withCredentials: true,
          headers: {
            Accept: "text/html",
            "Content-Type": "application/json",
            Cookie: `PHPSESSID=${sessionId}`,
          },
        }
      );

      const responseHtml = sendSmsResponse.data.toLowerCase();

      if (
        responseHtml.includes("<title>telca</title>") &&
        responseHtml.includes("<h3>login</h3>") &&
        responseHtml.includes('name="email"')
      ) {
        const autoLoginResponse = await axios.post(
          `${config.autoLoginURL}/v2/autologin`,
          { email: email, key: config.key },
          {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
          }
        );

        if (autoLoginResponse.data && autoLoginResponse.data.session_id) {
          sessionStorage.setItem(
            "sessionId",
            autoLoginResponse.data.session_id
          );
          sessionId = autoLoginResponse.data.session_id;
        }

        const updatedSendSmsResponse = await axios.get(
          `${config.autoLoginURL}/proxy/sendsms?sessionId=${sessionId}`,
          {
            withCredentials: true,
            headers: {
              Accept: "text/html",
              "Content-Type": "application/json",
              Cookie: `PHPSESSID=${sessionId}`,
            },
          }
        );
        setIframeContent(
          modifyHtmlContent(updatedSendSmsResponse.data, sessionId)
        );
      }
      setIframeContent(modifyHtmlContent(sendSmsResponse.data, sessionId));
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setloading(false);
    }
  };
  useEffect(() => {
    checkLogin();
  }, [sessionId]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        minHeight: "85vh",
        marginTop: "-3px",
        marginLeft: "-1px",
      }}
    >
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <iframe srcDoc={iframeContent} width="100%" height={720} />
      )}
    </div>
  );
};

export default SendMessage;
