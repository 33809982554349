import React, { ChangeEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PheonixTextField from "../Components/PheonixTextField";
import SubmitButton from "../Components/PheonixButton";
import PheonixPaper from "../Components/PheonixPaper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IconButton, styled } from "@mui/material";
import PheonixContainer from "../Components/PheonixContainer";
import { Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import headerlogo from "../assets/headerlogo.png";
import bg from "../assets/login_background.jpg";
import { useTheme, useMediaQuery } from "@mui/material";
import Alert from "@mui/material/Alert";
import { MODALTEXT, TEXT } from "../constant";
import { config } from "../config";
import axios from "axios";
import { useSession } from "../context/sessioncontext";
import { useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { dataCredential } from "../interfaces/DataCredential";
import CloseIcon from "@mui/icons-material/Close";

const Item = styled("div")({
  marginBottom: "5px",
  padding: "5px",
  textAlign: "center",
});

const SignUp: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");
  const { login } = useSession();

  useEffect(() => {
    const storedMessage = sessionStorage.getItem("message");
    if (storedMessage) {
      setAlerted(true);
      setMessage(storedMessage);
      sessionStorage.removeItem("message");
    }
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [Alerted, setAlerted] = useState(false);
  const [loginEmail, setLoginEmail] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleForgotPwd = () => {
    login();
    navigate("/users/password/new");
  };

  const handleSignUp = () => {
    window.location.href = TEXT.SIGNUP_URL;
  };

  const handleLogin = async () => {
    try {
      const tokenResponse = await axios.post(
        `${config.baseUrl}/v1/user/token`,
        { email, password }
      );

      const { success, token } = tokenResponse.data;

      if (!success) {
        setErrorMessage("Incorrect email or password");
        setShowAlert(true);
        return;
      }

      localStorage.setItem("authtoken", token);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("signup", "Signed in successfully.");
      sessionStorage.setItem("dashboard", "true");
      setLoginEmail(true);
      sessionStorage.setItem("loginemail", "true");

      setLoadingMessage(TEXT.LOGIN_WAIT);
      try {
        const autoLoginResponse = await axios.post(
          `${config.autoLoginURL}/v2/autologin`,
          { email: email, key: config.key },
          {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
          }
        );

        if (
          autoLoginResponse.data &&
          autoLoginResponse.data.session_id &&
          autoLoginResponse.data.response != "Wrong login credentials"
        ) {
          sessionStorage.setItem(
            "sessionId",
            autoLoginResponse.data.session_id
          );
        } else if (
          autoLoginResponse.data.response === "Wrong login credentials"
        ) {
          const autosignResponse = await axios.post(
            `${config.autoLoginURL}/v2/autosignup`,
            { email: email, key: config.key, company_name: "" },
            {
              withCredentials: true,
              headers: { "Content-Type": "application/json" },
            }
          );
          if (autosignResponse.data && autosignResponse.data.session_id) {
            sessionStorage.setItem(
              "sessionId",
              autosignResponse.data.session_id
            );
          }
        }
      } catch (autoLoginError) {
        console.error("Error during autologin:", autoLoginError);
      }

      login();
      navigate("/");
      setTimeout(() => {
        setLoadingMessage("");
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Login error:", error);
      setErrorMessage("An error occurred during login. Please try again.");
      setShowAlert(true);
    }
  };

  const exchangeGoogleToken = async (code: string) => {
    try {
      const params = new URLSearchParams();
      const googleClientId = config.googleClientId || "";
      const googleClientSecret = config.googleClientSecret || "";
      const googleRedirectUri = config.googleRedirectUri || "";
      params.append("client_id", googleClientId);
      params.append("client_secret", googleClientSecret);
      params.append("code", code);
      params.append("grant_type", "authorization_code");
      params.append("redirect_uri", googleRedirectUri);

      const response = await axios.post(
        "https://oauth2.googleapis.com/token",
        params
      );
      return response.data;
    } catch (error) {
      console.error("Error exchanging Google token:", error);
      throw error;
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const { code } = codeResponse;
        const tokenResponse = await exchangeGoogleToken(code);
        const { access_token, refresh_token, id_token } = tokenResponse;

        const decodedResponse: dataCredential = jwtDecode(id_token);
        const { email } = decodedResponse;

        sessionStorage.setItem("email", email);

        const userResponse = await axios.post(
          `${config.baseUrl}/v1/user/google/authorcreate`,
          {
            email,
            token: access_token,
            refreshtoken: refresh_token,
          }
        );

        if (userResponse.data.authToken?.token) {
          localStorage.setItem("authtoken", userResponse.data.authToken.token);
        }
        setLoadingMessage(TEXT.LOGIN_WAIT);
        try {
          const autoLoginResponse = await axios.post(
            `${config.autoLoginURL}/v2/autologin`,
            { email: email, key: config.key },
            {
              withCredentials: true,
              headers: { "Content-Type": "application/json" },
            }
          );

          if (
            autoLoginResponse.data &&
            autoLoginResponse.data.session_id &&
            autoLoginResponse.data.response != "Wrong login credentials"
          ) {
            sessionStorage.setItem(
              "sessionId",
              autoLoginResponse.data.session_id
            );
          } else if (
            autoLoginResponse.data.response === "Wrong login credentials"
          ) {
            const autosignResponse = await axios.post(
              `${config.autoLoginURL}/v2/autosignup`,
              { email: email, key: config.key, company_name: "" },
              {
                withCredentials: true,
                headers: { "Content-Type": "application/json" },
              }
            );

            if (autosignResponse.data && autosignResponse.data.session_id) {
              sessionStorage.setItem(
                "sessionId",
                autosignResponse.data.session_id
              );
            }
          }
        } catch (autoLoginError) {
          console.error("Error during autologin:", autoLoginError);
        }

        sessionStorage.setItem("message", TEXT.SIGNUPSUCCESS);
        sessionStorage.setItem("dashboard", "true");
        setLoginEmail(false);
        sessionStorage.setItem("loginemail", "false");
        login();
        navigate("/");
        setTimeout(() => {
          setLoadingMessage("");
          window.location.reload();
        }, 1000);
      } catch (error) {
        console.error("Google login error:", error);
      }
    },
    flow: "auth-code",
  });

  const paperHeight = showAlert
    ? isMobile
      ? "510px"
      : "490px"
    : isMobile
    ? "450px"
    : "430px";

  return (
    <>
      <div
        style={{
          backgroundColor: "#000000",
          width: "100%",
          height: "100%",
          padding: "10px 0",
          position: "relative",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <img
          src={headerlogo}
          alt="Logo"
          style={{
            width: isMobile ? "15%" : "8%",
            height: "auto",
            marginLeft: isMobile ? "20px" : "48px",
          }}
        />
      </div>

      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {loadingMessage && (
          <Alert
            style={{ color: "#3c763d", textAlign: "center", marginTop: "10px" }}
          >
            {loadingMessage}
          </Alert>
        )}
        {message && Alerted && (
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={() => setAlerted(false)}
              >
                <CloseIcon sx={{ fontSize: "small", color: "#676a6c" }} />
              </IconButton>
            }
            style={{ color: "#3c763d" }}
          >
            {message}
          </Alert>
        )}
        <PheonixContainer maxWidth="xs">
          <PheonixPaper
            style={{
              height: paperHeight,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: isMobile ? "280px" : "380px",
              borderRadius: "0",
              marginTop: isMobile ? "10vh" : "10vh",
              marginBottom: isMobile ? "10vh" : "10vh",
              marginLeft: isMobile ? "auto" : "initial",
              marginRight: isMobile ? "auto" : "initial",
              padding: isMobile ? "10px" : "5px 5px 15px 5px ",
            }}
          >
            <Stack direction="column">
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Item>
                  <Typography
                    style={{
                      color: "#676a6c",
                      fontFamily: "sans-serif",
                      marginTop: "10px",
                      fontSize: "24px",
                      fontWeight: 0,
                    }}
                  >
                    {TEXT.LOGIN_TXT}
                  </Typography>
                </Item>
                {showAlert && errorMessage && (
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={() => setShowAlert(false)}
                      >
                        <CloseIcon
                          sx={{ fontSize: "small", color: "#676a6c" }}
                        />
                      </IconButton>
                    }
                    style={{ color: "#A94342", background: "#F2DEDD" }}
                  >
                    {errorMessage}
                  </Alert>
                )}
                <Item>
                  <PheonixTextField
                    id="outlined-error"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    label="Email"
                    variant="outlined"
                    style={{
                      width: isMobile ? "260px" : "350px",
                      height: "48px",
                      color: "#676a6c",
                      fontFamily: "open-sans",
                    }}
                  />
                </Item>
                <Item>
                  <PheonixTextField
                    id="outlined-error"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    label="Password"
                    variant="outlined"
                    style={{
                      width: isMobile ? "260px" : "350px",
                      height: "48px",
                    }}
                  />
                </Item>
                <Item
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "left",
                    marginLeft: isTablet
                      ? "-200px"
                      : isMobile
                      ? "-120px"
                      : "-200px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="rememberMe"
                        color="primary"
                        size="small"
                      />
                    }
                    label={
                      <a
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          color: "#676a6c",
                          textDecoration: "none",
                        }}
                        onClick={() => {}}
                      >
                        {TEXT.REMEMBER}
                      </a>
                    }
                  />
                </Item>

                <Item>
                  <SubmitButton
                    label={"Sign In"}
                    style={{
                      width: isMobile ? "260px" : "350px",
                      textTransform: "none",
                      color: "#FFFFFF",
                      backgroundColor: "#1ab394",
                      borderColor: "transparent",
                    }}
                    onClick={handleLogin}
                  />
                </Item>
                <Item>
                  <SubmitButton
                    label={"Sign in with Google"}
                    onClick={() => googleLogin()}
                    style={{
                      width: isMobile ? "260px" : "350px",
                      textTransform: "none",
                      color: "#FFFFFF",
                      backgroundColor: "#D14836",
                      borderColor: "transparent",
                    }}
                  />
                </Item>
                <Item style={{ justifyContent: "center", textAlign: "center" }}>
                  <a
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      color: "#428bca",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    onClick={handleForgotPwd}
                  >
                    {TEXT.FORGOTPWD}
                  </a>
                </Item>
                <Item>
                  <SubmitButton
                    label={"Don't have an account?"}
                    onClick={handleSignUp}
                    style={{
                      backgroundColor: "#c2c2c2",
                      color: "#ffffff",
                      width: isMobile ? "260px" : "350px",
                      height: "35px",
                      textTransform: "none",
                      borderColor: "transparent",
                    }}
                  />
                </Item>
              </Box>
            </Stack>
          </PheonixPaper>
        </PheonixContainer>
      </div>
    </>
  );
};

export default SignUp;
