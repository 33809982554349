export const client_id = {
  clientID:
    "920691691966-89kqte9vaspkbc5q47uacv9pb5gefpvu.apps.googleusercontent.com",
};
export const TEXT = {
  SIGNUP_URL: "https://autodialers.callonthego.com/order",
  LOGIN_ERR: "Invalid email address or password",
  LOGIN_TXT: "Login or Create an Account",
  REMEMBER: "Remember Me",
  FORGOTPWD: "Forgot Password?",
  REVIEW: "Please review the problems below",
  BLANK: "cant be blank",
  ALERT:
    "You will receive an email with instructions on how to reset your password in few minutes",
  EMAIL: "* Email",
  LOGIN: "Login",
  NOACC: "Don't have an account?",
  PWD: "Password",
  CPWD: "Password confirmation",
  CHANGEPWD: "Change your password",
  PWDMIN: "password should be minimum 8 characters",
  PWDNOTMATCH: "passwords do not match",
  PLAYSTORE_LINK: "In progress",
  APPLESTORE:"In progress",
  LOGOUT: "Sign out",
  Submit: "Submit a spreadsheet file to import",
  ImportContacts:
    "Import your contacts with an existing spreadsheet that has the name, phone and email of your contacts.",
  INTEGRATIONS: "Integrations",
  MY_PROFILE: "My Profile",
  API: "API",
  ACCOUNT: "Accounts",
  EXPORT: "Export Contacts",
  GETSTART: "Getting Started",
  CONTENT:
    "Enter the name of the new list or existing list you wish to import these contacts from then",
  CONTENT_IN_BOLD: "press Enter to submit it.",
  UPLOAD_CONTENT:
    "Select file to upload (.csv, .xls, .xlsx) The selected file must include a single header row to enable mapping of the columns.",
  UPLOAD_CONTENT_1:
    "You will get an opportunity to verify the import looks correct before committing.",
  PREVTEW_CONTENT:
    "You must choose 5 columns to import even if they are blank choose 5!",
  TEMPLATE_HERE: "Here is a template. It is not required though. Download",
  SHARE: "Share",
  NOT_SEEING: "Not seeing what you're looking for?",
  SIGNUPSUCCESS: "Successfully authenticated from Google account",
  REFERRAL: "Your Referral link:",
  REF_LINK: "https://app.callonthego.com/referrals/YoRrxi",
  PREVIEW_LINK: "https://app.callonthego.com/referral_email_preview",
  SUB_TITLE:
    " This setup guide will help you reach your sales goals in 10 minutes or less by following 3 easy steps.",
  IMPORT_CONTACT: " Select a .csv file to import",
  CSV_CONTENT_1:
    " The CSV file must include a single header row to enable mapping of the columns.",
  CSV_CONTENT_2:
    "You will get an opportunity to verify the import looks correct before committing.",
  CSV_CONTENT_3: "Other formats (.xls, .xlsx) will be supported very soon.",
  DOWNLOAD_APP: "Download our app",
  LINKING_APP:
    "You can download our app on App Store or Google Play now, just click the links below.",
  START_CALL: "You're ready to start calling your leads!?",
  INFO_DETAILS:
    "After your list has been imported, it will automatically populate in CallOnTheGo app on your phone. After you've completed a [Call Loop]*, you will be able to review call data that is automatically imported for you to your Dashboard.",
  PREVIEW: "Preview",
  PREVIEW_CONTENT:
    "Please confirm the columns are correct, we tried to auto detect them for you.",
  HAVING_TROUBLE: " Having trouble importing contacts?",
  LOGOUTMSG: "Signed out successfully",
  IMPORTMSG:
    "Upload is in process once contacts will be imported page will get auto refresh !",
  NOFILE: "The file is empty or does not contain valid data.",
  Use_Zap:" Use Zap",
  Password:"Password",
  Password_confirmation : "Password confirmation",
  Create_from_scratch:"Create from scratch",
  learn_more:"learn more",
  COPYERROR:"Contacts already exist in the selected list.Copy operation skipped.",
  MOVEERROR:"Contacts already exist in the selected list.Move operation skipped.",
  FILEERROR:"There was an error parsing the file. Please try again.",
  FILELARGE:"File is too large. Please select a file smaller than 3MB.",
  FILE_UPLOAD : "Your file is uploading, Please wait...",
  GOOGLE_LOGIN_ERROR: " * Password update not supported for Google sign-in users",
  EMAIL_NOT_FOUND:"Email not found. Please use a valid email.",
  EMAIL_RESET_PASSWORD:"Failed to reset password. Please try again.",
  IMPORT_TEXT_ALREADY:"The contact already exists in the list.",
  NO_CONTACT:"No contact is selected",
  LOGIN_WAIT:"Please wait, logging you in..."
};

export const BUTTON = {
  EDIT_LIST: "Edit List",
  DELETE_LIST: "Delete List",
  NEW_LIST: "New List",
  CHOOSE_LIST: "1.Choose List",
  UPDATE_FILE: "2.Upload file",
  PREVIEW: "3.Preview",
  PERVIOUS: "Previous",
  NEXT: "Next",
  NEWCONTACT: "New Contact",
  IMPORT: "Import",
  REMOVE_CONTACT: "Remove Contact",
  DELETE_CONTACT: "Delete Contact",
  USEZAP: "Use this workflow",
  SEE_PERVIEW: "See Perview",
  IMPORT_BUTTON: "1. Import",
  APPS: "2. Apps",
  GET_STARTED: "3. Get Started",
  CHOOSE_FILE: "Choose file",
  UPLOAD: "Upload",
  UPDATE: "Update",
  CREATE: "Create",
};

export const CARDURL = {
  FIRST_URL:
    "https://zapier.com/sign-up?template=16338&utm_source=partner&utm_medium=embed&utm_campaign=zap_templates_element&provider=callonthego&entry-point-location=partner_embed&referer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&referrer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&next=%2Fwebintent%2Fcreate-zap%3Ftemplate%3D16338%26utm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26provider%3Dcallonthego%26entry-point-location%3Dpartner_embed%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26referrer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26attempt_id%3D33d3bf73-f7e9-4756-a893-a32ca9a8c412",
  SECOND_URL:
    "https://zapier.com/sign-up?template=26862&utm_source=partner&utm_medium=embed&utm_campaign=zap_templates_element&provider=callonthego&entry-point-location=partner_embed&referer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&referrer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&next=%2Fwebintent%2Fcreate-zap%3Ftemplate%3D26862%26utm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26provider%3Dcallonthego%26entry-point-location%3Dpartner_embed%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26referrer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26attempt_id%3D2916c278-a8c7-428b-89df-19fd3176402e",
  THIRD_URL:
    "https://zapier.com/sign-up?template=26863&utm_source=partner&utm_medium=embed&utm_campaign=zap_templates_element&provider=callonthego&entry-point-location=partner_embed&referer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&referrer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&next=%2Fwebintent%2Fcreate-zap%3Ftemplate%3D26863%26utm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26provider%3Dcallonthego%26entry-point-location%3Dpartner_embed%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26referrer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26attempt_id%3D8c9a8391-f40d-4fca-8d06-cda1596c2629",
  FOURTH_URL:
    "https://zapier.com/sign-up?template=26864&utm_source=partner&utm_medium=embed&utm_campaign=zap_templates_element&provider=callonthego&entry-point-location=partner_embed&referer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&referrer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&next=%2Fwebintent%2Fcreate-zap%3Ftemplate%3D26864%26utm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26provider%3Dcallonthego%26entry-point-location%3Dpartner_embed%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26referrer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26attempt_id%3D0404b549-30a1-43ce-8700-fed2b7ec0991",
  FIFTH_URL:
    "https://zapier.com/sign-up?template=26886&utm_source=partner&utm_medium=embed&utm_campaign=zap_templates_element&provider=callonthego&entry-point-location=partner_embed&referer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&referrer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&next=%2Fwebintent%2Fcreate-zap%3Ftemplate%3D26886%26utm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26provider%3Dcallonthego%26entry-point-location%3Dpartner_embed%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26referrer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26attempt_id%3D2a6f151e-a165-435e-9912-aeb388057340",
  SIXTH_URL:
    "https://zapier.com/sign-up?template=26945&utm_source=partner&utm_medium=embed&utm_campaign=zap_templates_element&provider=callonthego&entry-point-location=partner_embed&referer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&referrer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&next=%2Fwebintent%2Fcreate-zap%3Ftemplate%3D26945%26utm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26provider%3Dcallonthego%26entry-point-location%3Dpartner_embed%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26referrer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26attempt_id%3Da09dbe32-9cc5-4b7e-aa27-bd89fa52ab8c",
  SEVENTH_URL:
    "https://zapier.com/sign-up?template=26949&utm_source=partner&utm_medium=embed&utm_campaign=zap_templates_element&provider=callonthego&entry-point-location=partner_embed&referer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&referrer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&next=%2Fwebintent%2Fcreate-zap%3Ftemplate%3D26949%26utm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26provider%3Dcallonthego%26entry-point-location%3Dpartner_embed%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26referrer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26attempt_id%3D0026ddd2-a490-4511-8992-d857aefe255c",
  EIGHT_URL:
    "https://zapier.com/sign-up?template=26952&utm_source=partner&utm_medium=embed&utm_campaign=zap_templates_element&provider=callonthego&entry-point-location=partner_embed&referer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&referrer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&next=%2Fwebintent%2Fcreate-zap%3Ftemplate%3D26952%26utm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26provider%3Dcallonthego%26entry-point-location%3Dpartner_embed%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26referrer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26attempt_id%3D21651e83-4f35-479c-bb86-375ddfdd6dff",
  NINE_URL:
    "https://zapier.com/sign-up?template=26957&utm_source=partner&utm_medium=embed&utm_campaign=zap_templates_element&provider=callonthego&entry-point-location=partner_embed&referer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&referrer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&next=%2Fwebintent%2Fcreate-zap%3Ftemplate%3D26957%26utm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26provider%3Dcallonthego%26entry-point-location%3Dpartner_embed%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26referrer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26attempt_id%3D0a95d058-9da5-47e5-9ab3-a640c24ff750",
  TENTH_URL:
    "https://zapier.com/sign-up?template=543538&utm_source=partner&utm_medium=embed&utm_campaign=zap_templates_element&provider=callonthego&entry-point-location=partner_embed&referer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&referrer=https%3A%2F%2Fapp.callonthego.com%2Fusers%2Fedit%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit&next=%2Fwebintent%2Fcreate-zap%3Ftemplate%3D543538%26utm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26provider%3Dcallonthego%26entry-point-location%3Dpartner_embed%26referer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26referrer%3Dhttps%253A%252F%252Fapp.callonthego.com%252Fusers%252Fedit%253Futm_source%253Dpartner%2526utm_medium%253Dembed%2526utm_campaign%253Dzap_templates_element%2526referer%253Dhttps%25253A%25252F%25252Fapp.callonthego.com%25252Fusers%25252Fedit%26attempt_id%3D19aa396a-86ab-4306-bdb7-1a7bba878207",
};

export const URL = {
  CREATE_FROM_SCRATCH_URL:
    "https://zapier.com/app/login?utm_source=partner&utm_medium=embed&utm_campaign=zap_templates_element&next=%2Fwebintent%2Fcreate-zap%2F%3Futm_source%3Dpartner%26utm_medium%3Dembed%26utm_campaign%3Dzap_templates_element%26attempt_id%3Dd1001d9f-22d4-46ce-8706-5f2fa2139cf5&redirect_cause=auth-required",
  LEARN_MORE_URL:
    "https://zapier.com/workflows?utm_source=partner&utm_medium=embed&utm_campaign=zap_templates_element",
  FB_URL: "https://www.facebook.com/your-facebook-page",
  TWITTER_URL:
    "https://x.com/intent/post?url=https%3A%2F%2Fapp.callonthego.com%2Freferrals%2FNMCK1A",
  LINKEDIN_URL:
    "https://www.linkedin.com/feed/?linkOrigin=LI_BADGE&shareActive=true&shareUrl=https%3A%2F%2Fapp.callonthego.com%2Freferrals%2FNMCK1A",
  GOOGLE_URL:
    "https://play.google.com/store/apps/details?id=com.callonthego.android_alpha",
  APP_STORE_URL:
    "https://apps.apple.com/us/app/auto-dialer-by-callonthego/id940024826",
  MAILID: "mailto:mark@callonthego.com",
  LISTID: "http://localhost:3000/lists/",
  IMPORTCONTACTS_YT_LINK: "https://www.youtube-nocookie.com/embed/LQcY9E0lFNo?si=mApUrAGY1TgBLS4D&amp;start=1"
};
export const HELP = {
  TEXT1: "Connect CallOnTheGo to hundreds of other apps with Zapier",
  TEXT2:
    "lets you connect CallOnTheGo to 1,000+ other web services. Automated connections called Zaps, set up in minutes with no coding, can automate your day-to-day tasks and build workflows between apps that otherwise wouldn't be possible.",
  TEXT3:
    "Each Zap has one app as the **Trigger**, where your information comes from and which causes one or more **Actions** in other apps, where your data gets sent automatically.",
  TEXT4: "Getting Started with Zapier",
  TEXT5: " Sign up for a free",
  TEXT6:
    ", from there you can jump right in. To help you hit the ground running, here are some popular pre-made Zaps.",
  TEXT7: "Create from scratch",
  TEXT8: " learn more",
  TEXT9: "How do I connect CallOnTheGo to Zapier?",
};
export const MODALTEXT = {
  EDITLIST: "Edit List",
  LISTNAME: "List Name",
  LISTDESC: "List Description",
  CANCEL: "Cancel",
  UPDATE: "Update",
  DELLIST: "Delete List",
  DELETECONFIRM: " Are you sure you want to delete the ",
  NO: " No",
  YES: " Yes",
  NEWLIST: "New List",
  NEWCONTACT: "New Contact",
  FIRSTNAME: "First Name",
  LASTNAME: "Last Name",
  PHONE: "* Phone",
  EMAIL: "Email",
  LIST: "List",
  LISTSUCCESS: "New List has been created successfully.",
  ERROR: "Error:",
  LISTUPDATE: "List has beed updated successfully.",
  LISTDEL: "List has been deleted successfully.",
  CONTACTDEL:
    "Contact(s) has been removed from only this list. If you want to remove from all lists, select the contact and press Delete Contact.",
  CONTACTCREATE: "Contact has been created successfully.",
  DELCONTACT: "Contact(s) has been deleted from all lists successfully.",
  NODATA: "There are no contacts in the",
  MOVE: "Contacts are successfully moved",
  COPY: "Contacts are successfully copy",
  NOLISTUPDATE: "You can not update this list.",
  ERROR_FETCHING: "Error fetching list data",
};
